.Title {
    text-align: center;
    font-size: 35px;
    font-family: Arial, Helvetica, sans-serif;
}

.Content {
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 80vw;
    margin:auto;
}

.Button {
    background-color: white;
    border-style: none;
    display: block;
    font-family: 'Open Sans', 'Poppins', 'Times New Roman', Times, serif;
    font-size: 15px;
    box-shadow: 2px 2px 2px #c6c6c6;
    padding-right: 5px;
    padding-left: 5px;
}

.Button:hover {
    box-shadow: 2px 2px 2px #696868;
}

.ContentSection{
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Following section vvvv is for Calculators sub page */

.PageContainer {
    padding-top: 10px;
    max-width: 80vw;
    font-family: 'Open Sans', 'Poppins', 'Times New Roman', Times, serif;
    margin: auto;
    margin-bottom: 100px;
}

.PageContainer h1 {
    font-size: 30px;
    text-align: center;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    width: fit-content;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 40px;
}

.PageContainer p {
    font-size: 20px;
    font-weight: 500;
}

/* Following section ^^^^ is for Calculators sub page */

.Background {
    height: fit-content;
    background: linear-gradient(140deg, #FFFFFF 20%, #c2c1c1);
}

.CenterScroll {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}

.Fast {
    z-index: 10;
    display: flex;
    position: absolute;
    justify-content:unsafe;
}

.anim {
    z-index: 0;
}

.Button.Link {
    display: block;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    max-width: fit-content;
    margin: auto;
    justify-content: space-around;
}

.ImageCover {
    background-size: cover;
    height: fit-content;
    height: 80vh;
    width: 100vw;
    background-position: center;
    background-attachment: fixed;
}

/* For the about page */

.Bio {
    margin: auto;
    font-family: serif;
    max-width: 80vw;
    padding-top: 5vh;
    margin-bottom: 10vh;
}

.Bio h1 {
    font-size: 45px;
    justify-content: space-around;
    max-width: fit-content;
    margin: auto;
}

.Bio p {
    font-size: 20px;
}

.Bio ul {
    font-size: 20px;
    padding-left: 5vw;
    list-style: circle;
}

.Bio a {
    color: rgb(21, 1, 103);
}
