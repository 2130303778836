body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Padding {
  padding-top: 100px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
  background: #fff;
  background-color: #fff;
  color: #212529;
  position: -webkit-sticky;
  position: sticky;
}

.nav-area {
  display: flex;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  height: 37px;
  width: 37px;
  padding-top: 3px;
  padding-left: 1px;
  color: inherit;
  margin-right: 20px;
}

.logo:hover {
  border-radius: 100%;
  padding-top: 3px;
  padding-left: 1px;
  height: 37px;
  width: 37px;
  background-color: rgb(225, 225, 225);
}

.menus {
  display: flex;
  justify-content: right;
  position: right;
  align-items: right;
  flex-wrap: wrap;
  list-style: none;
  place-content: right;
}

.menu-items {
  position: relative;
  font-size: 14px;
  
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

/* .dropdown:hover {
  display: inline;
} */

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}
.Title {
    text-align: center;
    font-size: 35px;
    font-family: Arial, Helvetica, sans-serif;
}

.Content {
    text-align: left;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 80vw;
    margin:auto;
}

.Button {
    background-color: white;
    border-style: none;
    display: block;
    font-family: 'Open Sans', 'Poppins', 'Times New Roman', Times, serif;
    font-size: 15px;
    box-shadow: 2px 2px 2px #c6c6c6;
    padding-right: 5px;
    padding-left: 5px;
}

.Button:hover {
    box-shadow: 2px 2px 2px #696868;
}

.ContentSection{
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Following section vvvv is for Calculators sub page */

.PageContainer {
    padding-top: 10px;
    max-width: 80vw;
    font-family: 'Open Sans', 'Poppins', 'Times New Roman', Times, serif;
    margin: auto;
    margin-bottom: 100px;
}

.PageContainer h1 {
    font-size: 30px;
    text-align: center;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    justify-content: space-around;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 40px;
}

.PageContainer p {
    font-size: 20px;
    font-weight: 500;
}

/* Following section ^^^^ is for Calculators sub page */

.Background {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: linear-gradient(140deg, #FFFFFF 20%, #c2c1c1);
}

.CenterScroll {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}

.Fast {
    z-index: 10;
    display: flex;
    position: absolute;
    justify-content:unsafe;
}

.anim {
    z-index: 0;
}

.Button.Link {
    display: block;
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
    justify-content: space-around;
}

.ImageCover {
    background-size: cover;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    height: 80vh;
    width: 100vw;
    background-position: center;
    background-attachment: fixed;
}

/* For the about page */

.Bio {
    margin: auto;
    font-family: serif;
    max-width: 80vw;
    padding-top: 5vh;
    margin-bottom: 10vh;
}

.Bio h1 {
    font-size: 45px;
    justify-content: space-around;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
}

.Bio p {
    font-size: 20px;
}

.Bio ul {
    font-size: 20px;
    padding-left: 5vw;
    list-style: circle;
}

.Bio a {
    color: rgb(21, 1, 103);
}

.Video-Container {
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh;
    /* overflow: hidden; */
}

.Video-Container video {
    width: 100%;
    /* height: 100%; */
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}
.Container {
    max-width: 80vw;
    margin:auto;
    font-family: 'Open Sans', 'Poppins', 'Times New Roman', Times, serif;
}

.Container h3 {
    padding-top: 10px;
}

.Calc {
    display:flex;
    flex-wrap: wrap;
}

.Form {
}

.Form h2 {
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 10px;
}

.Field { 
    max-width: 80vw;
}

.Field h3 {
    font-weight: 400;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-right: 10px;
}

.Field input {
    height: 25px;
}
/* For the Resume WebPage */

.Resume.Button {
    border-style: none;
    background-color: rgb(229, 229, 229);
    border-radius: 5px;
    padding-top: 2px;
    font-family: 'Times New Roman', Times, serif;
    font-size: medium;
    color: rgb(0, 0, 0);
}

.Resume.Education.Small {
    font-size: 25px;
    font-weight: 100;
    align-items: left;
    text-align:left;
    margin:auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    
}

.Resume.Header.List li{ 
    padding-left: 30px;
    font-size: 20px;
}


.Resume.Education.Large {
    font-size: 30px;
    font-weight: 300;
    align-items: left;
    text-align:left;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    justify-content: space-between;
}

.Resume.Background {
    padding-top: 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #f0f0f0;
    padding-bottom: 50px;
}

.Resume.Experience.Title {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    align-items: center;
    text-align:center;
    margin:auto;
    display: flex;
    justify-content: space-between;
    max-width: 80vw;
    font-size: 28px;
    padding-top: 15px;
}


/* Checked */
.Resume.Normal{
    text-align: left;
    font-size: 17px;
    padding-bottom: 5px;
    font-family: 'Times New Roman', Times, serif;
}

.Resume.Normal strong {
    font-weight: bolder;
}


/* Checked */
.Resume.Education {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    align-items: center;
    text-align:center;
    margin:auto;
    display: flex;
    justify-content: space-between;
    max-width: 80vw;
    font-size: 20px;
}

.Resume.List {
    padding-top: 10px;
}

.Resume li{
    padding-left: 10px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 17px;
}

.Resume.Bullet {
    color:black;
    font-family: 'Times New Roman', Times, serif;
    text-align:left;
    padding-left: 15px;
}

.Resume.Container {
    color: black;
    font-family: 'Times New Roman', Times, serif;
    align-items: center;
    text-align:center;
    margin:auto;
    max-width: 80vw;
    padding-top: 5px;
}

/* Checked */
.Resume.Title{
    font-family: 'Times New Roman', Times, serif;
    font-size: 23px;
}

.Resume.Title h1{
    border-bottom: black;
    border-bottom-style: solid;
    max-width: 80vw;
    margin: auto; 
}

/* Checked */
.Resume.Container h2{
    border-bottom: black;
    font-family: 'Times New Roman', Times, serif;
    border-bottom-style: solid;
    padding-top: 10px;
    font-size: 28px;
    text-align: left;
}

.Resume.Title ul{
    list-style: none;
    display:flex;
    font-size: 23px;
    margin:auto;
    justify-content: center;
}

.Resume.Container h1 {
    color: black;
    max-width: inherit;
    text-align: left;
    border-style: none;
    border-bottom: 2px;
    border-bottom-color: black;
    border-bottom-style: solid;
    padding-bottom: 5px;
    padding-top: 5px;
}

/* Resume webpage cSS ^^^^ */
